/* ✨ Allgemeine Stile */
:root {
  --bg-light: #fffdf5ca;
  --bg-dark: #222;
  --btn-bg: #9d8f6376;
  --btn-hover: #09ff00;
  --text-light: white;
  --text-dark: black;
}


/* 🌟 Modal Container */
.about {
  font-family: Lausanne, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 130%;
  display: flex;
  position: fixed;
  inset: 0; /* Kurzform für top, left, right, bottom */
  width: 100%;
  height: 100%;
  background-color: var(--bg-light);
  background-image: url('https://www.transparenttextures.com/patterns/ecailles.png');
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: auto;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

/* 🎨 Inhalt */
.about .abstand {
  font-family: Lausanne, sans-serif;
  max-width: 50rem;
  max-height: 90vh;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0.75rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow-y: auto;
}

/* 📌 Überschriften & Text */
.about h2 {
  font-family: Lausanne, sans-serif;
  font-size: clamp(1.5rem, 3vw, 2rem);
  font-weight: 700;
  margin-bottom: 1rem;
 
}

.about p {
  font-family: Lausanne, sans-serif;
  font-size: clamp(0.9rem, 2.5vw, 1.1rem);
  font-weight: 400;
  line-height: 130%;
  word-wrap: break-word;
}

.abstand
{ font-family: Lausanne, sans-serif;
   margin-right: 20rem;
   margin-left: 2rem;
  line-height: 130%;
}


/* ✅ CTA Button */
.cta-button {
  font-family: Lausanne, sans-serif;
  margin-top: 1rem;
  padding: 0.75rem 0.75rem;
  background-color: var(--btn-bg);
  border-radius: 3rem;
  color: var(--text-light);
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cta-button:hover {
  font-family: Lausanne, sans-serif;
  background-color: var(--btn-hover);
  transform: scale(1.05);
}

.cta-button:active {
  font-family: Lausanne, sans-serif;
  background-color: var(--btn-active);
  transform: scale(0.98);
}

/* 🎭 Dark & Light Mode */
.dark-background {
  background-color: var(--bg-dark);
  color: var(--text-light);
}

.light-background {
  background-color: var(--bg-light);
  color: var(--text-dark);
}

/* 🎚 Collapsible Section */
.collapsible-section {
  opacity: 0;
  max-height: 0;
  overflow: auto;
  transition: max-height 0.5s ease, opacity 0.3s ease-in-out;
}

.collapsible-section.expanded {
  opacity: 1;
  max-height: 500px; /* Adjust as needed */
}

.about p {
  font-family: Lausanne, sans-serif;
  font-size: clamp(0.9rem, 2.5vw, 1.1rem);
  font-weight: 400;
  line-height: 130%;
  word-wrap: break-word;
}


/* 📲 Mobile Anpassungen */
@media (max-width: 768px) { /* Tablet */
  .cta-button {
    max-width: 90%;
    font-size: 0.95rem;
    padding: 0.7rem 0.5rem;
    line-height: 120%;

  }

.abstand
{  margin-right: 2rem;

  line-height: 130%;
}
}

@media (max-width: 480px) { /* Smartphone */
  .cta-button {
    font-size: 0.8rem;
    padding: 0.6rem 0.5rem;
    width: 100%;
    text-align: center;
    line-height: 120%;

  }

  .abstand
  { margin-right: 2rem;
    margin-left: 1rem;
    line-height: 130%;
  }

  .about h2 {
    font-family: Lausanne, sans-serif;
    font-size: 1.5rem;
  }

  .about p {
    font-family: Lausanne, sans-serif;
    font-size: 0.9rem;
    line-height: 120%;
  }

  .abstand {
    font-family: Lausanne, sans-serif;
      font-size: 1.5rem;
  }
}

