.image-gallery {
    display: flex;
    flex-wrap: wrap; /* Allow the images to wrap when necessary */
    justify-content: center; /* Center images horizontally */
    align-items: center; /* Center images vertically */
    gap: 10px; /* Add a gap between images */
    margin: 20px 25px;
    width: 95%;
    max-width: 95%;
}


.image-gallery img {
  display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive grid with columns */
    gap: 15px; /* Space between images */
    margin: 20px auto;
    max-width: 95%;
}

/* Optional: Handle portrait images */
.image-gallery img.portrait {
  height: 100%;
  max-height: 100%; /* Beispiel: 20rem für Portraitbilder, kann je nach Bedarf angepasst werden */
  object-fit: cover;
}

.modal-content-container {
  /* Adjust the max width to prevent overflowing */
  align-content: center;
  max-width: 100%;
  margin: 0 auto;
}

.video-container {
  align-content: center;
  width: 100%;           /* Make sure the container takes up 100% of its parent */
  max-width: 80%;        /* Adjust this as needed to limit the container width */
  margin: 20px auto;     /* Center the container */
  box-sizing: border-box;
}

.video-gallery {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 20px 0;
  width: 100%;    /* Ensure gallery takes up full container width */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.video-gallery video { /* Ensure videos never exceed their container's width */
  width: 100%;      /* Make videos responsive to the container */
  max-width: 95%; /* Limit max-width of videos */
  border-radius: 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
  object-fit: scale-down; /* Ensure videos stay within container's bounds */
  box-sizing: border-box; /* Include padding/border in width calculation */
}

/* Modal container */
.modal {
  font-family: Lausanne, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 130%;
  display: flex;
  align-content: center;
  position: fixed;
  top: 0rem;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  backdrop-filter: blur(10px); /* Background blur */
}
.modal-section {
  padding: clamp(3rem, 5vw, 3rem);
  margin-bottom: 1rem;
  line-height: 140%;
  background-color: #fffdf5ca;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  border: 3px solid transparent; /* Invisible border for alignment */
  z-index: 0;
  box-sizing: border-box;
}


/* Modal content styles */
.modal-content {
  position: relative;
  width: 75%;
  max-width: 1000px;
  align-content: center;
  max-height: 90vh;
  padding: 2rem;
  background-color: #fffdf5e1;
  margin: auto;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content .close {
  position: sticky;
  right: 0.8rem; /* Abstand vom rechten Rand */
  top: 0;
  background: none;
  border: none;
  font-size: 3rem;
  cursor: pointer;
  color: #000;
  padding: 0.8rem;
  line-height: 1;
  z-index: 800; /* Damit er über dem Inhalt bleibt */
  align-self: flex-end; /* Button nach rechts schieben */}

.modal-content .close:hover {
  color: #40ff00;
  transition: color 0.3s ease;
}

/* FadeIn animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Image caption (grey and smaller font size) */
.image-caption {
  color: rgb(165, 165, 165); /* Soft grey text */
  font-size: 0.8rem; /* Small font size */
  font-weight: 400; /* Thin font weight */
  margin-top: 0.4rem; /* Small space between image and caption */
  text-align: center; /* Center-align the caption */
  line-height: 1.5; /* Slightly increased line height for readability */
  letter-spacing: 0.02rem; /* Optional: Slight letter-spacing for clarity */
}

/* Modal grid for columns */
.responsive-columns {
  display: grid;
  grid-template-columns: 1fr; /* Single column for mobile */
  gap: 1.4rem;
  margin: 1.4rem 0;
}

.column {
  padding: 0;
  align-items: center;
}

.column h3 {
  font-weight: 400;
  margin-bottom: 1rem;
}

.column h2 {
  font-weight: 400;
  margin-bottom: 1rem;
}

.column h4 {
  font-weight: 400;
}

/* Checklist styling */
.checklist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid; /* Use grid layout */
  grid-template-columns: auto 1fr; /* Bullet in the first column, text in the second */
  gap: 10px 20px; /* Vertical and horizontal space between items */
}


/* List item styling */
.checklist li {
  display: contents; /* Makes each list item behave like its content */
  line-height: 1.5;
  border-top: 10px;
}

/* Custom bullet point */
.checklist li::before {
  content: "✦";         /* Custom bullet */
  color: rgb(0, 0, 0);  /* Bullet color */
  grid-column: 1;       /* Force the bullet to stay in the first column */
  align-self: center;   /* Vertically center the bullet */
}
/* list styling */
.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid; /* Use grid layout */
  grid-template-columns: auto 1fr; /* Bullet in the first column, text in the second */
  gap: 10px 20px; /* Vertical and horizontal space between items */
}


/* List item styling */
.list li {
  display: contents; /* Makes each list item behave like its content */
  line-height: 1.5;
  font-weight: 400;
  border-top: 10px;
}
.list li::before {
  
  color: rgb(0, 0, 0);  /* Bullet color */
  grid-column: 1;       /* Force the bullet to stay in the first column */
  align-self: center;
}

.filter-chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; /* Space between the chips */
  margin: 0.2rem 0;
  justify-content: center;
  align-items: center;
}

.filter-chip {
  font-weight: 400;
  font-size: 1rem;
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers text horizontally */
  align-items: center; /* Centers text vertically */
  padding: 6px 12px;
  background-color: #9d8f6376; /* Light gray background */
  border-radius: 3rem; /* Makes the chip look like a bubble */
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.filter-chip:hover {
  background-color:#09ff00;
  border-color: #001effad;
}

.filter-chip.active {
  background-color: #ffffff; /* Blue when active */
  color: #09ff00;
  border-color: #0056b3;
}

/* Hover effect on touch devices */
@media (hover: none) {
  .image-gallery img:hover {
    transform: none; /* Disable hover effect on touch devices */
  }
}

/* Modal content adjustments for mobile */
@media screen and (max-width: 768px) {
  /* Ensure the modal takes up the whole screen */
  .modal {
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    position: fixed; /* Fixed position to stay on screen */
    top: 0;
    left: 0;
    padding: 0; /* Remove padding */
    background: rgba(0, 0, 0, 0.5); /* Optional: add background overlay */
    z-index: 9999; /* Ensure the modal stays on top */
    margin: 0; /* Ensure no margin */
  }

  /* Ensure the modal content takes up the full height and width */
  .modal-content {
    width: 99%; /* Full width */
    height: 100%; /* Full height */
    justify-content: center; /* Center content horizontally */
    align-items: center;
    padding: 0.8rem; /* Adjust padding for mobile */
    display: flex;
    flex-direction: column; /* Stack content vertically */
  }

  .modal-content-container {
    font-size: 0.875rem;
    line-height: 130%;
    justify-content: center; /* Center content horizontally */
    align-items: center;
    padding: 0; /* No padding */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow-y: auto; /* Allow scrolling if content overflows */
  }

  .modal-section {
    margin: 0; /* Remove default margin */
    margin-bottom: 1.5rem; /* Erhöht den Abstand */
    padding: 0 1.5rem; /* Add horizontal padding for sections */
    justify-content: center; /* Centers images horizontally */
    align-items: center;
  }

  .modal-content .close {
    font-size: 1.5rem; /* Smaller close button */
  }
  .modal-section h1 {
    line-height: 120%;
  }

  .modal-section h2 {
    line-height: 120%;
  }

  .image-gallery {
    display: flex; /* Enables flexbox */
    flex-wrap: wrap; /* Allows images to wrap */
    justify-content: center; /* Centers images horizontally */
    align-items: center; /* Centers images vertically */
    gap: 8px; /* Reduced gap */
    margin-left: 7px;
  }

  /* Adjust image caption for smaller screens */
  .image-caption {
    font-size: 0.75rem;
    margin-top: 0.2rem;
    justify-content: center; /* Center content horizontally */
    align-items: center;
  }

  /* Image gallery styling */
  .image-gallery img {
    max-width: 100%;  /* Ensure images never exceed their container's width */
    width: auto; /* Ensures images maintain natural width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional: Add rounded corners */
    justify-content: center; /* Centers images horizontally */
    align-items: center;
    transition: transform 0.3s ease; /* Smooth hover effect */
    display: block; /* Removes any inline spacing issues */
    margin: 0 auto; /* Ensures proper centering */
  }

  /* Video gallery styling */
  .video-container {
    max-width: 100%; /* Ensure the container takes full width on small screens */
    height: auto;
    min-height: 200px;   /* Ensure there's at least some height for the video on mobile */
  }

  .video-gallery {
    display: flex;
    width: 100%;
    max-width: 100%;
    object-fit: contain;
    gap: 30px;
    justify-content: center;
    margin: 20px 0;
    flex-direction: column; /* Stack videos vertically on small screens */
    align-items: center;    /* Center videos when stacked */
  }

  .video-gallery video {
    width: 100%;        /* Make videos fill their container */
    max-width: 100%;    /* Ensure videos never exceed the container's width */
    border-radius: 12px; /* Rounded corners for videos */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
    object-fit: contain;  /* Ensure videos stay within container's bounds */
  }
}



