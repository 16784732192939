/* src/components/PasswordPage/PasswordPage.css */ 
.password-page {
   display: flex; 
   flex-direction:column; 
   align-items: center; 
   justify-content: center; 
   height: 100vh;
   background-image: url('/src/Assets/itstime.gif'); 
   background-size: cover; 
   background-position: center; 
   font-family: Arial, sans-serif; } 
   
.password-page h1 { 

  font-family: Lausanne, sans-serif;
  font-size: 2rem; 
  letter-spacing: 0.5rem;
  margin-bottom: 2rem; 
  color: #ffffff; } 

.password-page form { 
  display: flex; 
  flex-direction: column; 
  align-items: center; } 

.password-page input { 
  padding: 1.3rem; 
  font-size: 2rem; 
  margin-bottom: 2rem; 
  border: 1px solid #ccc; 
  border-radius: 100px; } 

.password-page button 
{ padding: 0.5rem 1rem; 
  font-size: 1rem; 
  background-color: #ff80c2; 
  color: rgb(255, 255, 255); 
  border: none; 
  border-radius: 100px;; 
  cursor: pointer; } 

.password-page button:hover { 
  background-color: #40ff00; } 

.password-page .error { 
  color: rgb(255, 0, 0); 
  margin-top: 1rem; }


  /* Mobile adjustments */
@media (max-width: 768px) { /* Tablet and smaller devices */
  .password-page h1 {
    font-size: 1.5rem; /* Smaller title size */
    letter-spacing: 0.3rem; /* Adjust spacing for smaller screen */
  }

  .password-page input {
    font-size: 1.5rem; /* Smaller input font */
    padding: 1rem; /* Smaller padding */
    width: 90%; /* Input is more responsive */
    max-width: 300px; /* Ensure it doesn't stretch too much */
  }

  .password-page button {
    font-size: 1rem; /* Smaller button font size */
    padding: 0.7rem 1.2rem; /* Adjust button size */
  }
}

@media (max-width: 480px) { /* Smartphone and very small devices */
  .password-page h1 {
    font-size: 1.2rem; /* Even smaller title for very small screens */
    letter-spacing: 0.2rem; /* Adjust further for small screens */
  }

  .password-page input {
    font-size: 1.2rem; /* Further smaller input */
    padding: 0.9rem; /* More compact padding */
    width: 90%; /* Full width for input */
    max-width: 250px; /* Further limit max width */
  }

  .password-page button {
    font-size: 0.9rem; /* Smallest button font */
    padding: 0.6rem 1rem; /* Adjust button size */
  }
}