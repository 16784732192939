footer {
  margin-top: 0rem;
  font-family: Lausanne, sans-serif;
  padding: 1rem;
  background-color:  #ece9e4bc;
  background-image: url('https://www.transparenttextures.com/patterns/ecailles.png'); /* Paper texture */
  font-size: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0; /* Ensure footer does not overlap content */
}

li {
  list-style: none;
  align-items: center;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  footer {
    padding: 5px 10px; /* Reduced padding */
    font-size: 0.8rem;  /* Smaller font size for mobile */
  }
}
