nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 60px;
  
  z-index: 1;
  background-color: #ECE9E4;
  color: inherit;
  transition: background-color 0.3s, color 0.3s;
  position: sticky;
  top: 0;
}

nav.dark {
  background-color: #000000;
  color: #ECE9E4;
  background-image: url('https://www.transparenttextures.com/patterns/ecailles.png'); /* Paper texture */
}

.nav-logo {
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nav-logo:hover {
  transform: scale(1.1);
  transition: 0.3s ease;
  cursor: pointer;
}

.nav-logo img {
  width: 100%; /* Adjust logo size */
  height: auto;
  cursor: pointer;
}

ul {
  font-family: Lausanne, sans-serif;
  display: inline-flex;
  gap: 0.6rem;
  padding: 0;
  margin: 0;
  align-items: center;
  line-height: 1;
}

li {
  list-style: none;
  align-items: center;
  line-height: 1;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.nav-image {
  width: 40%; 
 /* Adjust size */
  height: auto;
}


.contact-link img:hover,
.about-link img:hover 
.community-link img:hover {
  transform: scale(1.1);
  transition: 0.3s ease;
  cursor: pointer;
}


/* Mobile Styles */
@media (max-width: 768px) {


  /* Position the links with images to the top right corner */
  .contact-link {
    position: absolute;
    top: 15px;
    right: -70px;
    display: inline-block;
    margin-left: 20px; /* Space between the two icons */
  }

  .about-link img {
    position: absolute;
    top: 0px;
    right: 130px;
    display: inline-block;
    margin-left: 0px;
  }


  .community-link img {
    position: absolute;
    top: 0px;
    right: 130px;
    display: inline-block;
    margin-left: 0px;
  }
}

