.carousel {
  background-color: #ECE9E4;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

.image-container img {
    min-height: 30rem;
  }


.carousel::-webkit-scrollbar {
  display: none;
}

.inner-carousel {
  display: flex;
  height: auto;
  gap: 0px;
}

.item {
  height: auto;
  width: auto;
  padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.item img {
  width: auto;
  max-width: 400px;
  max-height: 30rem;
  height: auto;
  border-radius: 0rem;
  transition: border-radius 0.5s;
}

.item img:hover {
  opacity: 0.6;
  transition: 0.5s;
  border-radius: 2rem;
}

.title-container {
  margin-top: 10px;
  padding: 0 10px;
  text-align: center;
}

.image-title-small {
  font-family: Lausanne, sans-serif;
  font-size: 0.9rem;
  font-weight: normal;
  margin-bottom: 5px;
}

.image-title-main {
  font-family: Lausanne, sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media screen and (max-width: 768px) {
  .carousel {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: 96%;
    margin: 0 auto;
    display: inline-flex;
    scroll-behavior: smooth;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
    scroll-snap-stop: always;
    scroll-padding-left: 16px;
    scroll-padding-right: 150px;
  }

  .carousel::-webkit-scrollbar {
    display: none;
  }

  h1 {
    font-size: clamp(1.5rem, 4vw, 2.5rem);
    text-align: left;
  }

  .item {
    min-width: 200px;
    padding: 20px 10px;
    max-height: 35rem;
    height: 100%;
    flex: 0 0 auto;
    width: calc(100% - 32px);
    max-width: 300px;
    scroll-snap-align: start;
  }

  .image-container img {
    pointer-events: none;
    height: auto;
    margin-right: 2px;
    width: 100%;
    max-width: 400px;
    min-width: 300px; /* Ensures smaller images don't shrink below this */
    max-height: 40rem;
    min-height: 30rem;
  }

  .inner-carousel {
    display: flex;
    gap: 16px;
    width: max-content; /* Ensure inner carousel adjusts based on child items */
    flex-wrap: nowrap; /* Prevent wrapping */
  }

  .title-container {
    font-size: clamp(1rem, 3vw, 1.3rem);
    margin-bottom: 15px;
    text-align: center;
  }

  .image-title-small {
    font-size: clamp(0.8rem, 2.5vw, 1rem);
    font-weight: 500;
  }

  .image-title-main {
    font-size: clamp(1.2rem, 3.5vw, 1.7rem);
    line-height: 1.2;
  }
}
